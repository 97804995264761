import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Space, Table } from "antd";
import React, { useState } from "react";
import "./../css/playertable.css"; // Use the same CSS as PlayerTable

function RbTable({ data, isNFL }) {
  const [searchText, setSearchText] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = data.filter((player) =>
      player.last_name.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleChange = (pagination, filters, sorter) => {
    // Retain only non-null filters from the incoming `filters` object
    const updatedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        updatedFilters[key] = filters[key]; // Only retain active filters
      }
    });

    // Merge with existing filters, keeping previously active filters
    const mergedFilters = { ...filteredInfo, ...updatedFilters };

    setFilteredInfo(mergedFilters); // Update state with merged filters
    setSortedInfo(sorter);

    // Apply filters manually to the data
    let updatedData = data;

    Object.keys(mergedFilters).forEach((key) => {
      const filterValues = mergedFilters[key];
      if (filterValues) {
        updatedData = updatedData.filter((record) => {
          const columnFilter = columns.find((col) => col.dataIndex === key);
          if (columnFilter && columnFilter.onFilter) {
            return columnFilter.onFilter(filterValues, record);
          }
          return true;
        });
      }
    });

    setFilteredData(updatedData); // Update the displayed data
    setPagination(pagination); // Update pagination state
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText("");
    setFilteredData(data); // Reset data to original
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchText("");
    setFilteredData(data); // Reset data to original
  };

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      text: value,
      value,
    }));
  };

  //   const filteredData = data
  //     .filter(
  //       (item) =>
  //         item.first_name.toLowerCase().includes(searchText) ||
  //         item.last_name.toLowerCase().includes(searchText)
  //     )
  //     .map((item) => ({
  //       ...item,
  //       key: item.id, // Ensure unique keys
  //     }));

  const createNumericFilter = (dataIndex, filteredInfo, setFilteredInfo) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Min ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value, selectedKeys[1]])}
          style={{ marginBottom: 8, display: "block" }}
          type="number"
        />
        <Input
          placeholder={`Max ${dataIndex}`}
          value={selectedKeys[1]}
          onChange={(e) => setSelectedKeys([selectedKeys[0], e.target.value])}
          style={{ marginBottom: 8, display: "block" }}
          type="number"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setFilteredInfo({
                ...filteredInfo,
                [dataIndex]: selectedKeys,
              });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setFilteredInfo({
                ...filteredInfo,
                [dataIndex]: null,
              });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
    ),
    onFilter: (value, record) => {
      if (!Array.isArray(value)) return true; // No filtering if value is not an array
      const [min, max] = value.map((v) => (v ? parseFloat(v) : undefined));
      const recordValue = parseFloat(record[dataIndex]);
      return (
        (min === undefined || recordValue >= min) &&
        (max === undefined || recordValue <= max)
      );
    },
  });

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "120px",
      fixed: "left",
      render: (text, record) => (
        <span className="nowrap-tier" style={{ whiteSpace: "nowrap" }}>
          {`${record.first_name.charAt(0)}. ${record.last_name}`}
        </span>
      ),
    },
    {
      title: isNFL ? "Team" : "College", // Adjust the title to be general
      dataIndex: isNFL ? "team" : "school",
      key: isNFL ? "team" : "school",
      filters: getUniqueValues(filteredData, isNFL ? "team" : "school"),
      filteredValue: filteredInfo[isNFL ? "team" : "school"] || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) =>
        record[isNFL ? "team" : "school"]?.includes(value),
      sorter: (a, b) =>
        a[isNFL ? "team" : "school"]?.localeCompare(
          b[isNFL ? "team" : "school"]
        ),
      sortOrder:
        sortedInfo.columnKey === (isNFL ? "team" : "school") &&
        sortedInfo.order,
      width: "90px",
    },
    {
      title: "PPG",
      dataIndex: "ppg",
      key: "ppg",
      width: "70px",
      sorter: (a, b) => b.ppg - a.ppg,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "ppg" && sortedInfo.order,
      className: "columnSorted",
      ...createNumericFilter("ppg", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["ppg"]) &&
          filteredInfo["ppg"].some((val) => val !== undefined && val !== null);
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "CARRY%",
      dataIndex: "carrypercent",
      key: "carrypercent",
      width: "90px",
      sorter: (a, b) => b.carrypercent - a.carrypercent,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "carrypercent" && sortedInfo.order,
      ...createNumericFilter("carrypercent", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["carrypercent"]) &&
          filteredInfo["carrypercent"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "YPTRA",
      dataIndex: "yptra",
      key: "yptra",
      width: "90px",
      sorter: (a, b) => b.yptra - a.yptra,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "yptra" && sortedInfo.order,
      ...createNumericFilter("yptra", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["yptra"]) &&
          filteredInfo["yptra"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "RUSH TD MS",
      dataIndex: "rushtdms",
      key: "rushtdms",
      width: "130px",
      sorter: (a, b) => b.rushtdms - a.rushtdms,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "rushtdms" && sortedInfo.order,
      ...createNumericFilter("rushtdms", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["rushtdms"]) &&
          filteredInfo["rushtdms"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "RUSH PPG",
      dataIndex: "rushppg",
      key: "rushppg",
      width: "110px",
      sorter: (a, b) => b.rushppg - a.rushppg,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "rushppg" && sortedInfo.order,
      ...createNumericFilter("rushppg", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["rushppg"]) &&
          filteredInfo["rushppg"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "REC PPG",
      dataIndex: "receivingppg",
      key: "receivingppg",
      width: "110px",

      sorter: (a, b) => b.receivingppg - a.receivingppg,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "receivingppg" && sortedInfo.order,
      ...createNumericFilter("receivingppg", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["receivingppg"]) &&
          filteredInfo["receivingppg"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "TS%",
      dataIndex: "targetshare",
      key: "targetshare",
      width: "70px",

      sorter: (a, b) => b.targetshare - a.targetshare,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "targetshare" && sortedInfo.order,
      ...createNumericFilter("targetshare", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["targetshare"]) &&
          filteredInfo["targetshare"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "YPTPA",
      dataIndex: "yptpa",
      key: "yptpa",
      width: "70px",

      sorter: (a, b) => b.yptpa - a.yptpa,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === "yptpa" && sortedInfo.order,
      ...createNumericFilter("yptpa", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["yptpa"]) &&
          filteredInfo["yptpa"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
  ];

  const tableStyle = {
    width: "90%",
    margin: "0 auto",
    marginTop: "20px",
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            color: "#e91e63",
            backgroundColor: "#3f3f3f",
            borderColor: "#3f3f3f",
            headerBg: "#3f3f3f",
            headerColor: "#ffffff",
            rowHoverBg: "#f83f7c",
          },
        },
      }}
    >
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16, width: "100%" }} direction="vertical">
          <div className="search-and-buttons">
            <Input
              placeholder="Search by last name"
              value={searchText}
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              className="search-input"
            />
            <div className="button-group">
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button onClick={clearAll}>Clear All</Button>
            </div>
          </div>
        </Space>
        <Table
          size="small"
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          rowKey="id"
          pagination={pagination}
          scroll={{ y: 400 }}
          rowClassName={(record, index) =>
            `dark-mode-row ${index % 2 === 0 ? "even-row" : "odd-row"}`
          }
        />
      </div>
    </ConfigProvider>
  );
}

export default RbTable;
