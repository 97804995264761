const baseURL = process.env.REACT_APP_API_URL;
const frontendToken = process.env.REACT_APP_FRONTEND_TOKEN;

export async function getRookies() {
  try {
    // Adding more information on the request configuration

    const response = await fetch(`${baseURL}/api/rookies`, {
      headers: {
        "X-Frontend-Token": frontendToken,
      },
    });

    if (!response.ok) {
      // Logging detailed response data in case of error
      console.error("Response error details:", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();

    try {
      const data = JSON.parse(text); // Try to parse the text as JSON
      console.log(data);
      return data; // Return the parsed data
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      console.error("Text that caused parsing error:", text);
      return [];
    }
  } catch (error) {
    console.error("Error in fetch operation:", error);
    return [];
  }
}
