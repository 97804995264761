import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import { getTrades } from "../services/tradefinderservice";
import { getActivePlayersSorted } from "./../services/playerservice";

const { Option } = Select;

export default function TradeForm({ isNFL }) {
  const [firstPlayer, setFirstPlayer] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [includePicks, setIncludePicks] = useState(true);
  const [teamStatus, setTeamStatus] = useState("CONTENDER");
  const [tradeType, setTradeType] = useState("trading_up");
  const [leagueSettings, setLeagueSettings] = useState("SUPERFLEX");
  const [tradeResult, setTradeResult] = useState(null);

  const handleTradeClick = async () => {
    if (firstPlayer) {
      const response = await getTrades(
        firstPlayer.id,
        tradeType,
        teamStatus,
        includePicks,
        leagueSettings
      );

      if (response.success) {
        console.log();
        setTradeResult(response.data);
      } else {
        setTradeResult({ message: "No Trade found" });
      }
    }
  };

  // Fetch players on mount
  useEffect(() => {
    async function fetchPlayers() {
      try {
        const data = await getActivePlayersSorted(isNFL, isNFL ? false : true);
        setAllPlayers(data);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    }
    fetchPlayers();
  }, [isNFL]);

  // Handle player selection
  const handleFirstPlayerChange = (value) => {
    const selectedPlayer = allPlayers.find((player) => player.id === value);
    setFirstPlayer(selectedPlayer);
  };

  // Sort players alphabetically
  const sortedPlayers = (list) => {
    return [...list].sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
      const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  };

  const playersList = sortedPlayers(allPlayers);

  // Styles
  const formStyle = {
    background: "#1e1e2e",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
    width: "100%",
    maxWidth: "400px",
    color: "#ffffff",
    fontFamily: "Arial, sans-serif",
  };

  const selectStyle = {
    width: "100%",
    marginBottom: "10px",
    backgroundColor: "#2a2a3a",
    color: "#ffffff",
  };

  const buttonStyle = {
    background: "linear-gradient(135deg, #007bff, #00c3ff)",
    border: "none",
    padding: "12px",
    borderRadius: "8px",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "16px",
    cursor: "pointer",
    marginTop: "15px",
    width: "100%",
    transition: "0.3s",
  };

  const tradeContainerStyle = {
    marginTop: "20px",
    padding: "15px",
    background: "#2a2a3a",
    borderRadius: "10px",
    color: "#ffffff",
    maxWidth: "400px",
    wordBreak: "break-word",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          backgroundColor: "#121212",
        }}
      >
        <form style={formStyle}>
          <h2
            style={{
              textAlign: "center",
              color: "#00c3ff",
              marginBottom: "12px",
            }}
          >
            Trade Submission
          </h2>

          {/* First Player Dropdown */}
          <label>Player to trade</label>
          <Select
            showSearch
            placeholder="Select First Player"
            style={selectStyle}
            onChange={handleFirstPlayerChange}
            value={firstPlayer ? firstPlayer.id : null}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          >
            {playersList.map((player) => (
              <Option
                key={player.id}
                value={player.id}
                label={`${player.first_name} ${player.last_name}`}
              >
                {player.first_name} {player.last_name}
              </Option>
            ))}
          </Select>

          {/* Include Picks Checkbox */}
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <input
              type="checkbox"
              checked={includePicks}
              onChange={() => setIncludePicks(!includePicks)}
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
            <label>Include Picks</label>
          </div>

          {/* Team Status Dropdown */}
          <label>Team Status</label>
          <select
            value={teamStatus}
            onChange={(e) => setTeamStatus(e.target.value)}
            style={selectStyle}
          >
            <option value="CONTENDER">Contender</option>
            <option value="REBUILDER">Rebuilder</option>
            <option value="NEITHER">Neither</option>
          </select>

          {/* Trade Type Dropdown */}
          <label>Trade Type</label>
          <select
            value={tradeType}
            onChange={(e) => setTradeType(e.target.value)}
            style={selectStyle}
          >
            <option value="trading_up">Trade Up</option>
            <option value="trading_down">Trade Down</option>
          </select>

          {/* League Settings Dropdown */}
          <label>League Settings</label>
          <select
            value={leagueSettings}
            onChange={(e) => setLeagueSettings(e.target.value)}
            style={selectStyle}
          >
            <option value="SUPERFLEX">Superflex (SF)</option>
            <option value="1QB">1QB</option>
          </select>

          {/* Submit Button */}
          <Button style={buttonStyle} onClick={handleTradeClick}>
            Submit Trade
          </Button>
        </form>
      </div>

      {/* Trade Result Section */}
      {tradeResult && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div style={tradeContainerStyle}>
            {tradeResult.message ? (
              <p>{tradeResult.message}</p>
            ) : (
              <>
                <h3 style={{ textAlign: "center", color: "#00c3ff" }}>
                  Trade Result
                </h3>
                <p>
                  <strong>Total Value In:</strong> {tradeResult.total_value_in}
                </p>
                <p>
                  <strong>Total Value Out:</strong>{" "}
                  {tradeResult.total_value_out}
                </p>

                <p>
                  <strong>Trade In:</strong>
                </p>
                <ul>
                  {tradeResult.trade_in.map((item, index) => (
                    <li key={index}>
                      {item.name} - Value: {item.value}
                    </li>
                  ))}
                </ul>

                <p>
                  <strong>Trade Out:</strong>
                </p>
                <ul>
                  {tradeResult.trade_out.map((item, index) => (
                    <li key={index}>
                      {item.name} - Value: {item.value}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
