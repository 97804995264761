import { React } from "react";
import PlayerCard from "../views/playercard";
import devytab from "./../assets/images/Devy_Players_Tab.png"; // Importing the image
import rookietab from "./../assets/images/Rookies_tab.png"; // Importing the image
import styles from "./../css/playerspage.module.css"; // Convert CSS to module and import here

import { useEffect } from "react";
import useIsMobile from "../services/useIsMobile";
import PlayerCardMobile from "../views/playercardmobile";
import RookieTable from "../views/rookietable";
import "./../css/playerspage.css";

function RookiesPage({
  players,
  handleRowClick,
  selectedPlayer,
  setSelectedPlayer,
  isNFL = true,
}) {
  useEffect(() => {
    const handleScroll = () => {
      document.querySelectorAll(".fade-in").forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          el.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const isMobile = useIsMobile(); // Use the custom hook to detect screen size

  return (
    <div className={styles.playerspage}>
      {/* Add the image at the very top */}
      <img
        src={isNFL ? rookietab : devytab} // Conditional rendering of the image source
        alt="Performance Overview"
        className={styles.performanceImage}
      />
      <div style={{ marginTop: "10px" }}></div>

      {/* New Section: Three Images in Asymmetrical Row
      <div className={styles.imageRow}>
        <img
          src={isNFL ? playerTab1 : devytab1}
          alt="Player Tab 1"
          className={styles.smallImage}
        />
        <img
          src={isNFL ? playerTab2 : devytab2}
          alt="Player Tab 2"
          className={styles.largeImage}
        />
        <img
          src={isNFL ? playerTab3 : devytab3}
          alt="Player Tab 3"
          className={styles.smallImage}
        />
      </div> */}

      <div className={styles.playerstooltext}>
        <h2 className={styles.playerstooltitle}>ROOKIES TOOL</h2>
        <p className={styles.playerstooldescription}>
          Dive into our 2025 Dynasty Rookie Board – your go-to resource for
          evaluating this year's incoming class. We’ve ranked the top rookies
          based on projected fantasy impact, talent, opportunity, and team fit
          to give you a clear draft-day edge. Each player profile includes key
          stats, positional breakdowns, and school background to help you make
          informed decisions. Whether you're prepping for your rookie draft or
          evaluating trade value, this board delivers a tiered, data-driven
          snapshot of where each rookie stands heading into the season.
        </p>
      </div>

      {/* Conditionally render PlayerCard or PlayerCardMobile based on screen size */}
      <div
        className={`${
          isMobile ? "player-card-container-mobile" : styles.playerCardContainer
        } ${selectedPlayer ? "" : styles.hidden}`}
      >
        {selectedPlayer &&
          (isMobile ? (
            <PlayerCardMobile
              player={selectedPlayer}
              onClose={() => setSelectedPlayer(null)}
              isNFL={false}
              isRookie={true}
              setSelectedPlayer={setSelectedPlayer}
            />
          ) : (
            <PlayerCard
              player={selectedPlayer}
              onClose={() => setSelectedPlayer(null)}
              isNFL={false}
              isRookie={true}
              setSelectedPlayer={setSelectedPlayer}
            />
          ))}
      </div>
      <div className={styles.tableContainer}>
        <RookieTable
          data={players}
          onRowClick={handleRowClick}
          setSelectedPlayer={setSelectedPlayer}
          isNFL={false}
        />
      </div>
    </div>
  );
}

export default RookiesPage;
