import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Space, Table } from "antd";
import React, { useState } from "react";
import "./../css/playertable.css"; // Import shared styles

function QbTable({ data, isNFL }) {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = data.filter((player) =>
      player.last_name.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleChange = (pagination, filters, sorter) => {
    // Retain only non-null filters from the incoming `filters` object
    const updatedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        updatedFilters[key] = filters[key]; // Only retain active filters
      }
    });

    // Merge with existing filters, keeping previously active filters
    const mergedFilters = { ...filteredInfo, ...updatedFilters };

    setFilteredInfo(mergedFilters); // Update state with merged filters
    setSortedInfo(sorter);

    // Apply filters manually to the data
    let updatedData = data;

    Object.keys(mergedFilters).forEach((key) => {
      const filterValues = mergedFilters[key];
      if (filterValues) {
        updatedData = updatedData.filter((record) => {
          const columnFilter = columns.find((col) => col.dataIndex === key);
          if (columnFilter && columnFilter.onFilter) {
            return columnFilter.onFilter(filterValues, record);
          }
          return true;
        });
      }
    });

    setFilteredData(updatedData); // Update the displayed data
    setPagination(pagination); // Update pagination state
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText("");
    setFilteredData(data); // Reset data to original
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchText("");
    setFilteredData(data); // Reset data to original
  };

  const getUniqueValues = (data, key) => {
    if (!data || data.length === 0) {
      return []; // Return an empty array if data is null or empty
    }
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      text: value,
      value,
    }));
  };

  const createNumericFilter = (dataIndex, filteredInfo, setFilteredInfo) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Min ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value, selectedKeys[1]])}
          style={{ marginBottom: 8, display: "block" }}
          type="number"
        />
        <Input
          placeholder={`Max ${dataIndex}`}
          value={selectedKeys[1]}
          onChange={(e) => setSelectedKeys([selectedKeys[0], e.target.value])}
          style={{ marginBottom: 8, display: "block" }}
          type="number"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setFilteredInfo({
                ...filteredInfo,
                [dataIndex]: selectedKeys,
              });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setFilteredInfo({
                ...filteredInfo,
                [dataIndex]: null,
              });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
    ),
    onFilter: (value, record) => {
      if (!Array.isArray(value)) return true; // No filtering if value is not an array
      const [min, max] = value.map((v) => (v ? parseFloat(v) : undefined));
      const recordValue = parseFloat(record[dataIndex]);
      return (
        (min === undefined || recordValue >= min) &&
        (max === undefined || recordValue <= max)
      );
    },
  });

  const columns = [
    {
      title: "Player Name",
      key: "name",
      fixed: "left",
      render: (text, record) => (
        <span className="nowrap-tier">
          {`${record.first_name.charAt(0)}. ${record.last_name}`}
        </span>
      ),
      width: "130px",
    },
    {
      title: isNFL ? "Team" : "College", // Adjust the title to be general
      dataIndex: isNFL ? "team" : "school",
      key: isNFL ? "team" : "school",
      filters: getUniqueValues(filteredData, isNFL ? "team" : "school"),
      filteredValue: filteredInfo[isNFL ? "team" : "school"] || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) =>
        record[isNFL ? "team" : "school"]?.includes(value),
      sorter: (a, b) =>
        a[isNFL ? "team" : "school"]?.localeCompare(
          b[isNFL ? "team" : "school"]
        ),
      sortOrder:
        sortedInfo.columnKey === (isNFL ? "team" : "school") &&
        sortedInfo.order,
      width: "90px",
    },

    {
      title: "PPG",
      dataIndex: "ppg",
      key: "ppg",
      sorter: (a, b) => b.ppg - a.ppg, // Default descending order
      sortOrder: sortedInfo.columnKey === "ppg" && sortedInfo.order,
      defaultSortOrder: "descend", // Default sort is descending
      width: "90px",
      ...createNumericFilter("ppg", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["ppg"]) &&
          filteredInfo["ppg"].some((val) => val !== undefined && val !== null);
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "PPPG",
      dataIndex: "pppg",
      key: "pppg",
      sorter: (a, b) => b.pppg - a.pppg, // Default descending
      sortOrder: sortedInfo.columnKey === "pppg" && sortedInfo.order,
      width: "90px",
      ...createNumericFilter("pppg", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["pppg"]) &&
          filteredInfo["pppg"].some((val) => val !== undefined && val !== null);
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "RPPG",
      dataIndex: "rppg",
      key: "rppg",
      sorter: (a, b) => b.rppg - a.rppg, // Default descending
      sortOrder: sortedInfo.columnKey === "rppg" && sortedInfo.order,
      width: "90px",
      ...createNumericFilter("rppg", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["rppg"]) &&
          filteredInfo["rppg"].some((val) => val !== undefined && val !== null);
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "AY/A",
      dataIndex: "aya",
      key: "aya",
      sorter: (a, b) => b.aya - a.aya, // Default descending
      sortOrder: sortedInfo.columnKey === "aya" && sortedInfo.order,
      width: "90px",
      ...createNumericFilter("aya", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["aya"]) &&
          filteredInfo["aya"].some((val) => val !== undefined && val !== null);
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "PA",
      dataIndex: "passattempts",
      key: "passattempts",
      sorter: (a, b) => b.passattempts - a.passattempts,
      sortOrder: sortedInfo.columnKey === "passattempts" && sortedInfo.order,
      width: "80px",
      ...createNumericFilter("passattempts", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["passattempts"]) &&
          filteredInfo["passattempts"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "YPA",
      dataIndex: "ypa",
      key: "ypa",
      sorter: (a, b) => b.ypa - a.ypa,
      sortOrder: sortedInfo.columnKey === "ypa" && sortedInfo.order,
      width: "90px",
      ...createNumericFilter("ypa", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["ypa"]) &&
          filteredInfo["ypa"].some((val) => val !== undefined && val !== null);
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "CARRY%",
      dataIndex: "carrypercent",
      key: "carrypercent",
      sorter: (a, b) => b.carrypercent - a.carrypercent,
      sortOrder: sortedInfo.columnKey === "carrypercent" && sortedInfo.order,
      width: "100px",
      ...createNumericFilter("carrypercent", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["carrypercent"]) &&
          filteredInfo["carrypercent"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "YPTRA",
      dataIndex: "yptra",
      key: "yptra",
      sorter: (a, b) => b.yptra - a.yptra,
      sortOrder: sortedInfo.columnKey === "yptra" && sortedInfo.order,
      width: "100px",
      ...createNumericFilter("yptra", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["yptra"]) &&
          filteredInfo["yptra"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "PASSTD%",
      dataIndex: "passtdpercent",
      key: "passtdpercent",
      sorter: (a, b) => b.passtdpercent - a.passtdpercent,
      sortOrder: sortedInfo.columnKey === "passtdpercent" && sortedInfo.order,
      width: "100px",
      ...createNumericFilter("passtdpercent", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["passtdpercent"]) &&
          filteredInfo["passtdpercent"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "RUSHTD%",
      dataIndex: "rushtdpercent",
      key: "rushtdpercent",
      sorter: (a, b) => b.rushtdpercent - a.rushtdpercent,
      sortOrder: sortedInfo.columnKey === "rushtdpercent" && sortedInfo.order,
      width: "100px",
      ...createNumericFilter("rushtdpercent", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["rushtdpercent"]) &&
          filteredInfo["rushtdpercent"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
    {
      title: "INT%",
      dataIndex: "passintpercent",
      key: "passintpercent",
      sorter: (a, b) => b.passintpercent - a.passintpercent,
      sortOrder: sortedInfo.columnKey === "passintpercent" && sortedInfo.order,
      width: "80px",
      ...createNumericFilter("passintpercent", filteredInfo, setFilteredInfo),
      filterIcon: () => {
        const isActive =
          Array.isArray(filteredInfo["passintpercent"]) &&
          filteredInfo["passintpercent"].some(
            (val) => val !== undefined && val !== null
          );
        return (
          <FilterFilled style={{ color: isActive ? "#f83f7c" : "#ffffff" }} />
        );
      },
    },
  ];

  const tableStyle = {
    width: "90%",
    margin: "0 auto",
    marginTop: "20px",
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            color: "#e91e63",
            backgroundColor: "#3f3f3f",
            borderColor: "#3f3f3f",
            headerBg: "#3f3f3f",
            headerColor: "#ffffff",
            darkItemSelectedBg: "#e91e63",
            darkItemHoverColor: "#e91e63",
            rowHoverBg: "#f83f7c",
          },
        },
      }}
    >
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16, width: "100%" }} direction="vertical">
          <div className="search-and-buttons">
            <Input
              placeholder="Search by last name"
              value={searchText}
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              className="search-input"
            />
            <div className="button-group">
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button onClick={clearAll}>Clear All</Button>
            </div>
          </div>
        </Space>
        <Table
          size="small"
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          rowKey="id"
          pagination={pagination}
          scroll={{ y: 400 }}
          rowClassName={(record, index) =>
            `dark-mode-row ${index % 2 === 0 ? "even-row" : "odd-row"}`
          }
        />
      </div>
    </ConfigProvider>
  );
}

export default QbTable;
