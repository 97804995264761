// src/views/headercomponent.js

import { MenuOutlined } from "@ant-design/icons"; // Import the hamburger icon
import { ConfigProvider, Drawer, Layout, Menu } from "antd";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./../css/headercomponent.css"; // Import your CSS file
import LoginComponent from "./logincomponent";

const { Header } = Layout;

const HeaderComponent = ({ isMobile }) => {
  // Accept isMobile prop
  const location = useLocation();
  const [visible, setVisible] = useState(false); // State to control Drawer visibility

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const menuItems = [
    {
      key: "/",
      label: (
        <Link to="/" onClick={closeDrawer}>
          HOME
        </Link>
      ),
    },
    {
      key: "/players",
      label: (
        <Link to="/players" onClick={closeDrawer}>
          PLAYERS
        </Link>
      ),
    },
    {
      key: "/tradefinder",
      label: (
        <Link to="/tradefinder" onClick={closeDrawer}>
          TRADEFINDER
        </Link>
      ),
    },
    {
      key: "/performance",
      label: (
        <Link to="/performance" onClick={closeDrawer}>
          PERFORMANCE
        </Link>
      ),
    },
    {
      key: "/compare",
      label: (
        <Link to="/compare" onClick={closeDrawer}>
          COMPARE
        </Link>
      ),
    },
    {
      key: "/similar",
      label: (
        <Link to="/similar" onClick={closeDrawer}>
          SIMILARITY
        </Link>
      ),
    },
    {
      key: "/rostership",
      label: (
        <Link to="/rostership" onClick={closeDrawer}>
          ROSTERSHIP
        </Link>
      ),
    },
    {
      key: "devy",
      label: "DEVY",
      children: [
        {
          key: "/devy/players",
          label: (
            <Link to="/devy/players" onClick={closeDrawer}>
              PLAYERS
            </Link>
          ),
        },
        {
          key: "/devy/performance",
          label: (
            <Link to="/devy/performance" onClick={closeDrawer}>
              PERFORMANCE
            </Link>
          ),
        },
        {
          key: "/devy/compare",
          label: (
            <Link to="/devy/compare" onClick={closeDrawer}>
              COMPARE
            </Link>
          ),
        },
        {
          key: "/devy/similar",
          label: (
            <Link to="/devy/similar" onClick={closeDrawer}>
              SIMILARITY
            </Link>
          ),
        },
      ],
    },
  ];

  const horizontalMenuItems = [
    {
      key: "nfl",
      label: "NFL",
      style: { color: "#000", fontWeight: 700 },
      children: [
        {
          key: "/players",
          label: <Link to="/players">PLAYERS</Link>,
          style: { color: "#000", fontWeight: 700 },
        },
        {
          key: "/rookies",
          label: <Link to="/rookies">ROOKIES</Link>,
          style: { color: "#000", fontWeight: 700 },
        },
        {
          key: "/performance",
          label: <Link to="/performance">PERFORMANCE</Link>,
          style: { color: "#000", fontWeight: 700 },
        },
      ],
    },
    {
      key: "devy",
      label: "DEVY",
      style: { color: "#000", fontWeight: 700 },
      children: [
        {
          key: "/devy/players",
          label: <Link to="/devy/players">PLAYERS</Link>,
          style: { color: "#000", fontWeight: 700 },
        },
        {
          key: "/devy/performance",
          label: <Link to="/devy/performance">PERFORMANCE</Link>,
          style: { color: "#000", fontWeight: 700 },
        },
      ],
    },
    {
      key: "tools",
      label: "TOOLS",
      children: [
        {
          key: "nfltools",
          label: "NFL",
          style: { color: "#000", fontWeight: 700 },
          children: [
            {
              key: "/compare",
              label: <Link to="/compare">COMPARE</Link>,
              style: { color: "#000", fontWeight: 700 },
            },
            {
              key: "/similar",
              label: <Link to="/similar">SIMILARITY</Link>,
              style: { color: "#000", fontWeight: 700 },
            },
            {
              key: "/rostership",
              label: <Link to="/rostership">ROSTERSHIP</Link>,
              style: { color: "#000", fontWeight: 700 },
            },
            {
              key: "/tradefinder",
              label: <Link to="/tradefinder">TRADEFINDER</Link>,
              style: { color: "#000", fontWeight: 700 },
            },
          ],
        },
        {
          key: "devytools",
          label: "DEVY",
          style: { color: "#000", fontWeight: 700 },
          children: [
            {
              key: "/devy/compare",
              label: <Link to="/devy/compare">COMPARE</Link>,
              style: { color: "#000", fontWeight: 700 },
            },
            {
              key: "/devy/similar",
              label: <Link to="/devy/similar">SIMILARITY</Link>,
              style: { color: "#000", fontWeight: 700 },
            },
          ],
        },
      ],
    },
  ];

  return (
    <Header className="header">
      <div className="centered-header">
        {isMobile ? (
          <>
            <MenuOutlined
              onClick={showDrawer}
              style={{ fontSize: "24px", color: "#ffffff" }}
            />
            <Drawer
              title="MENU"
              placement="left"
              onClose={closeDrawer}
              open={visible}
              key="left"
              width={240}
            >
              <Menu
                mode="vertical"
                selectedKeys={[location.pathname]}
                style={{ width: "100%", textAlign: "left", fontWeight: 700 }}
                items={menuItems}
              />
              <div className="login-container-override">
                <LoginComponent />
              </div>
            </Drawer>
          </>
        ) : (
          <>
            <div className="logo-container">
              <img
                src="/images/dynastyimpng.webp"
                alt="Logo"
                className="logo"
              />
            </div>
            <div className="menu-container">
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      horizontalItemSelectedColor: "#ffffff",
                      itemHoverColor: "#ffffff",
                    },
                  },
                }}
              >
                <Menu
                  mode="horizontal"
                  selectedKeys={[location.pathname]}
                  className="custom-menu"
                  items={horizontalMenuItems}
                />
              </ConfigProvider>
            </div>
          </>
        )}
        {!isMobile && (
          <div className="login-container">
            <LoginComponent />
          </div>
        )}
      </div>
    </Header>
  );
};

export default HeaderComponent;
