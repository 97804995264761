import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const frontendToken = process.env.REACT_APP_FRONTEND_TOKEN;

// Function to call the compare endpoint and get similarities by playerid and year
export async function getTrades(
  playerid,
  trade_type,
  team_status,
  include_picks,
  league_format
) {
  try {
    const response = await axios.get(`${baseURL}/api/tradefinder`, {
      params: {
        playerid: playerid,
        tradetype: trade_type,
        status: team_status,
        include_picks: include_picks, // Boolean, gets converted to string in request
        league_settings: league_format,
      },
      headers: {
        "X-Frontend-Token": frontendToken,
      },
    });

    if (response.status === 200) {
      return { success: true, data: response.data }; // Return trade data on success
    } else {
      return { success: false, message: "No valid trade found" };
    }
  } catch (error) {
    console.error("Error fetching trades:", error);
    return { success: false, message: "Failed to fetch trades" };
  }
}
